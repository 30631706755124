<div [ngClass]="{
  'mb-1': style === 'minimal',
  'mb-4': style !== 'minimal'
}">
  <a *ngIf="viewLink"
    [routerLink]="mjsJob.url"
     [queryParams]="linkQueryParams"
    class="btn btn-outline-primary btn-block apply-button text-left d-flex justify-content-between align-items-center">
    View
    <svg viewBox="0 0 24 24"
      [attr.width]="style === 'minimal' ? 18 : 24"
      [attr.height]="style === 'minimal' ? 18 : 24"
      aria-hidden="true">
      <path fill="currentColor" d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
    </svg>
  </a>
  <button *ngIf="!viewLink"
    class="btn btn-block apply-button d-flex align-items-center"
    [ngClass]="{
      'text-left': buttonActive,
      'text-center': !buttonActive,
      'justify-content-between': buttonActive,
      'justify-content-center': !buttonActive,
      'btn-primary': style !== 'minimal',
      'btn-outline-primary': style === 'minimal',
    }"
    (click)="apply()"
    [disabled]="!buttonActive">
    {{ buttonText }}
    <svg *ngIf="buttonActive && (!mjsJob.external_application_url || mjsJob.source == 'cornerstone')"
         viewBox="0 0 24 24"
         [attr.width]="style === 'minimal' ? 18 : 24"
         [attr.height]="style === 'minimal' ? 18 : 24"
         aria-hidden="true"
         class="float-right">
      <path fill="currentColor" d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
    </svg>
    <svg *ngIf="buttonActive && (mjsJob.external_application_url && mjsJob.source != 'cornerstone')"
      xmlns="http://www.w3.org/2000/svg"
     [attr.width]="style === 'minimal' ? 18 : 24"
     [attr.height]="style === 'minimal' ? 18 : 24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round">
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
    </svg>
  </button>
  <p *ngIf="description"
     class="mt-1 mb-0 d-block border rounded bg-white p-2 font-weight-bold text-center border-primary">
    {{ description }}
  </p>
</div>
