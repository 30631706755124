import {
  Inject,
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {
  fromEvent,
  Observable,
  Subscription,
  Subject
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import loadjs from 'loadjs';
import { environment } from '@env/environment';

declare var lumesseAnalytics: any;
declare var jQuery: any;

@Component({
  selector: 'mjs-rac',
  templateUrl: './rac.component.html',
  styleUrls: ['./rac.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RacComponent implements OnInit, OnDestroy {

  job_advert: any;
  profile: any;
  contextToken = null;
  loading: boolean;
  racMenuActive: boolean;
  job_id: number;
  positionElementsRetries: number;
  positionElementsTimeout: any;
  saveButtonClicked: boolean;
  fixedNav: boolean;
  scrollMax: boolean;
  fixedTopNav: boolean;

  private resizeObservable$: Observable<Event>;
  private resizeSubscription$: Subscription;
  private scrollObservable$: Observable<Event>;
  private scrollSubscription$: Subscription;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private doc: Document,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private toast: ToastrService,
    private renderer: Renderer2,
    private logger: NGXLogger) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.route.data.subscribe(res => {
        this.positionElementsRetries = 20;
        this.contextToken = res['contextToken'];
        this.job_advert = res['job'];

        const { resumeToken, jobId } = this.route.snapshot.queryParams;

        if (jobId) {
          this.job_id = jobId.split('-').reverse()[0];
        }

        if (this.job_advert.resume && this.job_advert.resume.length > 0 && !resumeToken) {
          this.warnOfIncompleteApplications();
        }
      });

      this.profile = this.oauthService.getIdentityClaims();

      // Set auth header and app version info for logger requests.
      this.logger.setCustomHttpHeaders(new HttpHeaders({
        'Authorization': `Bearer ${this.oauthService.getAccessToken()}`,
        'App-Version': environment.version,
        'App-Environment': environment.production ? 'production' : 'development'
      }));

      this.loadScriptsAndInitialize();

      this.resizeObservable$ = fromEvent(window, 'resize', { passive: true });
        this.resizeSubscription$ = this.resizeObservable$
          .pipe(takeUntil(this.onDestroy$)).subscribe(evt => {
            this.positionElements();
          });

      fromEvent(window, 'scroll', { passive: false }).pipe(
        takeUntil(this.onDestroy$),
      ).subscribe(() => {
        let scrollTop = this.doc.documentElement.scrollTop || this.doc.body.scrollTop;
        let scrollPoint = 168;

        if (this.doc.getElementById('banner-ad')) {
          const bannerAd = this.renderer.selectRootElement('.banner-ad', true);

          // Check if banner-ad is visible and if so adjust scrollPoint.
          if (!!(bannerAd.offsetWidth || bannerAd.offsetHeight || bannerAd.getClientRects().length)) {
            scrollPoint = scrollPoint + 90;
          }
        }

        this.fixedNav = scrollTop >= scrollPoint;
        this.fixedTopNav = this.doc.documentElement.offsetWidth < 1021 && scrollTop >= scrollPoint;

        const pageHeader = this.renderer.selectRootElement('.page-header', true);
        const sidebarNav = this.renderer.selectRootElement('.apply-menu-container-left', true);
        const sidebar = this.renderer.selectRootElement(
          '.tlk-apply-components-styling-context.tlk-components-vanilla-apply-content',
          true
        );

        const maxScroll = (sidebar.offsetHeight + 300) - ((pageHeader.offsetHeight + pageHeader.offsetTop) + sidebarNav.offsetHeight);

        this.scrollMax = scrollTop >= maxScroll;
      });
    }
  }

  public loadScriptsAndInitialize(): void {
    loadjs([
      `https://apply5.lumessetalentlink.com/apply-app/static/apply/release/3-LATEST/apply-all-in-one-namespaced.js`,
      `https://cdn-ui.lumessetalentlink.com/thirdparties/jquery/1.12.3/jquery.min.js`
    ], {
      before: (path, el) => {
        switch (path) {
          case `https://apply5.lumessetalentlink.com/apply-app/static/apply/release/3-LATEST/apply-all-in-one-namespaced.js`:
            el.setAttribute('data-talentlink-apply', '');
            el.setAttribute('data-talentlink-apply-layout', 'wcag');
            el.setAttribute('data-talentlink-apply-host', environment.lumesse_apply_host);
            el.setAttribute('data-talentlink-apply-config-key', environment.lumesse_config_key);
            el.setAttribute('data-talentlink-apply-autoload', 'false');
            el.setAttribute('data-lumesse-candidate-identity', JSON.stringify({
              contextToken: this.contextToken.token,
              email: this.profile.mail
            }));
            break;
        }
      },
      success: () => {
        if (window.hasOwnProperty('talentlink_rac')) {
          this.talentlinkLogging();
        }
      },
      error: (err: any) => {
        return window.location.href = '/user/applications?message=rac_error';
      }
    });
  }

  toggleRacMenu(): void {
    this.racMenuActive = !this.racMenuActive;
  }

  private positionElements(): any {
    this.positionElementsRetries -= 1;
    let elems = this.doc.getElementsByClassName('lumesse-apply-save-app-save-button');

    if (elems.length === 0) {
      if (this.positionElementsTimeout > 0) {
        clearTimeout(this.positionElementsTimeout);
      }

      return this.positionElementsTimeout = setTimeout(() => {
        this.positionElements()
      }, 500);
    }

    if (this.doc.documentElement.offsetWidth > 1020) {
      this.renderer.appendChild(
        this.renderer.selectRootElement('.apply-menu-container', true),
        this.renderer.selectRootElement('#nav-menu-collapse', true)
      );
      this.renderer.appendChild(
        this.renderer.selectRootElement('.apply-menu-container', true),
        this.renderer.selectRootElement('.lumesse-apply-save-app-save-button', true)
      );
    } else {
      this.renderer.appendChild(
        this.renderer.selectRootElement('#rac-mobile-nav-wrap', true),
        this.renderer.selectRootElement('#nav-menu-collapse', true)
      );
      this.renderer.appendChild(
        this.renderer.selectRootElement('.rac-mobile-nav', true),
        this.renderer.selectRootElement('.lumesse-apply-save-app-save-button', true)
      );
    }

    const app = this;

    this.doc.querySelector('.lumesse-apply-save-app-save-button').addEventListener('click', function() {
      if (!app.saveButtonClicked) {
        app.saveButtonClicked = true;
        setTimeout(function() {
          (<HTMLButtonElement>app.doc.querySelector('.lumesse-apply-save-app-save-button')).click();
        }, 500);
      }
    });
  }

  private warnOfIncompleteApplications(): void {
    let message = `You have ${this.job_advert.resume.length > 1 ? this.job_advert.resume.length.toString() : 'an'} incomplete application${this.job_advert.resume.length > 1 ? 's' : ''} for this position. ${this.job_advert.resume.length > 1 ? 'Select a' : 'Click the'} date below to continue or dismiss this message to start a new one.` + "\n";

    for (let i = 0; i < this.job_advert.resume.length; i += 1) {
      message += "\n" + `<a href="/rac?jobId=${this.route.snapshot.queryParams['jobId']}&resumeToken=${this.job_advert.resume[i].token}" style="display: block;">${this.job_advert.resume[i].updated}</a>`;
    }

    this.toast.warning(message, `Incomplete Application${this.job_advert.resume.length > 1 ? 's' : ''}`, {
      disableTimeOut: true,
      messageClass: 'toast-message incomplete-applications-toast'
    });
  }

  private talentlinkLogging(): void {
    (window as any).talentlink_rac.CustomerApi.readyToBind(function(binder){
      function onLoad() {
        jQuery('.apply-sna-show-description-button').bind('mouseenter mouseleave', function() {
          jQuery(this).click()
        })

        function hideFutureYearsOption() {
          jQuery.each(jQuery('.form-control-date-year select option'), function (el, i) {
            var currentYear = new Date().getFullYear();
            if (jQuery(i).val() > currentYear) {
              jQuery(this).remove()
            }
          })
        }

        hideFutureYearsOption();

        jQuery('.repeat-widget-button').click(function () {
          setTimeout(function () {
            hideFutureYearsOption();
          }, 150);
        });
      }

      (window as any).talentlink_rac.ApplyCustomerEvents.once((window as any).talentlink_rac.ApplyCustomerEvents.appInitialized, onLoad);
      binder.bind();
    });

    /**
     * The following commented methods are left for reference as we couldn't find them
     * documented anywhere.
     */

    // analyticsEnabled.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.analyticsEnabled', () => {
      // this.logger.debug('analyticsEnabled', e);
    // });

    // appInitialized.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.appInitialized', () => {
      this.logger.debug(`RAC App Initialized: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
      this.positionElements();
    });

    // applicationSaveFailed.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.applicationSaveFailed', () => {
      this.logger.debug(`RAC Application Save Failed: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
    });

    // applicationSaveSucceeded.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.applicationSaveSucceeded', () => {
      this.logger.debug(`RAC Application Save Succeeded: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
    });

    // applyInitializationFailed.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.applyInitializationFailed', () => {
      this.logger.debug(`RAC Apply Initialization Failed: User { this.profile.id } & Job ${this.job_id}`);
      // Redirect.
      // window.location.href = '/user/applications?message=';
    });

    // candidateIdentityAcquired.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.candidateIdentityAcquired', () => {
    //   // this.logger.debug('candidateIdentityAcquired', e);
    // });

    // componentLoadingFailed.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.componentLoadingFailed', () => {
      this.logger.debug(`RAC Component Loading Failed: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
      // Redirect?.
    });

    // documentUploadProgress.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.documentUploadProgress', () => {
    //   // this.logger.debug('documentUploadProgress', e);
    // });

    // elementFocused.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.elementFocused', () => {
    //   // this.logger.debug('elementFocused', e);
    // });

    // externalProfileReceived.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.externalProfileReceived', () => {
    //   // this.logger.debug('externalProfileReceived', e);
    // });

    // fieldsFilled.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.fieldsFilled', () => {
    //   // this.logger.debug('fieldsFilled', e);
    // });

    // formSubmission.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.formSubmission', () => {
      this.logger.debug(`RAC Form Submission: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
    });

    // menuItemSelected.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.menuItemSelected', () => {
      // this.logger.debug('menuItemSelected', e);
      this.racMenuActive = false;
    });

    // oauthPopupClosed.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.oauthPopupClosed', () => {
    //   // this.logger.debug('oauthPopupClosed', e);
    // });

    // scrollToElement.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.scrollToElement', () => {
    //   // this.logger.debug('scrollToElement', e);
    // });

    // submissionAccepted.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.submissionAccepted', () => {
      this.logger.debug(`RAC Submission Accepted: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
    });

    // applicationSaveFailed.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.submissionFailed', () => {
      this.logger.debug(`RAC Submission Failed: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
    });

    // validationFailed.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.validationFailed', () => {
    //   // this.logger.debug('validationFailed', e);
    // });

    // validationSuccedeed.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.validationSuccedeed', () => {
    //   // this.logger.debug('validationSuccedeed', e);
    // });

    // widgetsLoaded.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.widgetsLoaded', () => {
    //   // this.logger.debug('widgetsLoaded', e);
    // });

    // focusFormSection.
    // (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.focusFormSection', () => {
    //   // this.logger.debug('focusFormSection', e);
    // });

    // submissionAccepted.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.submissionAccepted', () => {
      this.logger.debug(`RAC Submission Accepted: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
      return window.location.href = '/survey';
    });

    // componentLoadingFailed.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.componentLoadingFailed', () => {
      this.logger.debug(`RAC Component Loading Failed: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
    });

    // applyInitializationFailed.
    (window as any).talentlink_rac.ApplyCustomerEvents.on('ApplyCustomerEvents.applyInitializationFailed', () => {
      this.logger.debug(`RAC Apply Initialization Failed: User ${this.profile.uid} ${this.profile.mail} & Job ${this.job_advert.job_number} [${this.job_id}] ${this.job_advert.title}`);
    });

  }

  public ngOnDestroy(): void {
    if (this.positionElementsTimeout) {
      clearTimeout(this.positionElementsTimeout);
    }

    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.renderer.removeClass(this.doc.body, 'rac-wcag');
    this.renderer.removeClass(this.doc.body, 'tlk-apply-standalone');
    this.renderer.removeClass(this.doc.body, 'talentlink-apply-layout-wcag');
    this.renderer.removeClass(this.doc.body, 'lumesse-has-menu');
    this.renderer.removeClass(this.doc.body, 'tlk-apply-components-styling-context');
    this.renderer.removeClass(this.doc.body, 'empty-state');
  }

}
