import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject,
  Renderer2
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { throwError, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { FormService } from '@app/services/form.service';

interface confirmField {
  keys: string[],
  message: string;
}

@Component({
  selector: 'mjs-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntityFormComponent implements OnInit, OnDestroy {

  @Input() entity: string;
  @Input() bundle: string;
  @Input() mode: string;
  @Input() entity_id: number;
  @Input() params: any;
  @Input() submit: any;
  @Input() recaptcha: boolean;
  @Input() formData: any;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter();
  @Output() submitForm: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup = new UntypedFormGroup({});
  data: any;
  submitted: boolean;
  browser: boolean;
  loading: boolean;
  confirmFields: confirmField[] = [];
  submitLabel?: string;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private formService: FormService,
    @Inject(PLATFORM_ID) private platform: string,
    private renderer: Renderer2) {
    this.browser = false;

    if (isPlatformBrowser(this.platform)) {
      this.browser = true;
    }
  }

  public ngOnInit(): void {
    if (!this.formData) {
      this.getForm();
    } else {
      this.data = { fields: this.formData };
      this.init();
    }
  }

  private getForm(): void {
    this.loading = true;

    this.formService.getForm(this.entity, this.bundle, this.mode, this.entity_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: data => {
          this.data = data;

          if (this.entity == 'user' && this.mode == 'settings') {
            for (let i = 0; i < data.fields.length; i += 1) {
              if (data.fields[i].field_name == 'field_security_answer') {
                data.fields[i].hideValue = true;
              }
            }

            data.fields.push({
              label: 'Current Password',
              id: 'existing',
              description: 'Required if you want to change your password',
              required: false,
              field_name: 'existing',
              field_type: 'password',
              confirm: false,
              maxlength: 255,
              type: 'pass',
              cardinality: -10,
              settings: {
                'max_length': 255
              }
            });

            data.fields.push({
              label: 'New Password',
              id: 'pass',
              required: false,
              field_name: 'pass',
              field_type: 'password',
              confirm: true,
              maxlength: 255,
              type: 'pass',
              cardinality: -10,
              settings: {
                'max_length': 255
              }
            });
          }
          this.init();
          this.loading = false;
        },
        error: error => {
          this.loading = false;
        }
      });
  }

  init = (): void => {
    for (let i = 0; i < this.data.fields.length; i += 1) {
      let validators = [];
      let value = '';

      if (this.data.fields[i].required) {
        validators.push(Validators.required);
      }

      if (this.data.fields[i].default_value) {
        value = this.data.fields[i].default_value;
      }

      if (this.data.fields[i].hasOwnProperty('value')) {
        if (this.data.fields[i].type && this.data.fields[i].type === 'boolean_checkbox') {
          value = this.data.fields[i].value ? 'checked' : '';
        }
        else {
          value = this.data.fields[i].value;
        }
      }

      if (this.params && this.params[this.data.fields[i].field_name]) {
        value = this.params[this.data.fields[i].field_name];
      }

      this.form.addControl(this.data.fields[i].field_name, new UntypedFormControl(value, validators));

      if (this.data.fields[i].type === 'pass') {
        validators.push(Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@#$%^&*()_+]).{10,}$/));
        this.form.get(this.data.fields[i].field_name)?.setValidators(validators);
        this.form.get(this.data.fields[i].field_name)?.updateValueAndValidity();
      }

      if (this.data.fields[i].type === 'pass' && this.data.fields[i].confirm) {
        this.form.addControl(`confirm__${this.data.fields[i].field_name}`, new UntypedFormControl(value, validators));

        this.confirmFields.push({
          keys: [this.data.fields[i].field_name, `confirm__${this.data.fields[i].field_name}`],
          message: 'The passwords must match'
        });
      }

      if (this.data.fields[i].type === 'options_select' && this.data.fields[i].settings.child_values) {
        this.form.addControl(this.data.fields[i].field_name + '__child', new UntypedFormControl(
          this.data.fields[i].child_value ? this.data.fields[i].child_value : this.data.fields[i].child_default_value ? this.data.fields[i].child_default_value : '',
          validators
        ));
      }
    }

    if (this.entity == 'user' && this.mode == 'settings') {
      this.form.controls['pass'].valueChanges.pipe(map((query: string) => query),
        filter((query: string) => query.length > -1), debounceTime(250),
        distinctUntilChanged(), takeUntil(this.onDestroy$))
        .subscribe((text: string) => {
          if (text) {
            for (let i = 0; i < this.data.fields.length; i += 1) {
              if (this.data.fields[i].field_name == 'pass' || this.data.fields[i].field_name == 'existing') {
                this.data.fields[i].required = true;
              }
            }

            this.form.get('existing').setValidators([Validators.required]);
            this.form.get('existing').updateValueAndValidity();
            this.form.get('pass').setValidators([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@#$%^&*()_+]).{10,}$/)]);
            this.form.get('pass').updateValueAndValidity();
            this.form.get('confirm__pass').setValidators([Validators.required]);
            this.form.get('confirm__pass').updateValueAndValidity();

            this.confirmFields.push({
              keys: ['pass', `confirm__pass`],
              message: 'The passwords must match'
            });
          } else {
            for (let i = 0; i < this.data.fields.length; i += 1) {
              if (this.data.fields[i].field_name == 'pass' || this.data.fields[i].field_name == 'existing') {
                this.data.fields[i].required = false;
              }
            }

            this.confirmFields = [];
            this.form.get('existing').setErrors(null);
            this.form.get('existing').setValidators(null);
            this.form.get('existing').updateValueAndValidity();
            this.form.get('pass').setErrors(null);
            this.form.get('pass').setValidators(null);
            this.form.get('pass').updateValueAndValidity();
            this.form.get('confirm__pass').setErrors(null);
            this.form.get('confirm__pass').setValidators(null);
            this.form.get('confirm__pass').updateValueAndValidity();
          }
        });
    }

    this.form.valueChanges.subscribe(val => {
      this.valueChanges.emit(val);
    });
  }

  get f(): any { return this.form.controls; }

  public onSubmit(): any {
    this.submitted = true;
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();

    if (this.form.invalid) {
      return;
    }

    if (this.confirmFields.length > 0) {
      for (let i = 0; i < this.confirmFields.length; i += 1) {

        if (this.form.value[this.confirmFields[i].keys[0]] !== this.form.value[this.confirmFields[i].keys[1]]) {
          return this.form.controls[this.confirmFields[i].keys[0]].setErrors({ mustMatch: true });
        }
      }
    }

    this.submitForm.emit({
      params: this.form.value,
      structure: this.data
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
