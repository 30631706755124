<article class="job-listing" *ngIf="mjsJob" [ngClass]="{ featured: mjsJob.featured === '1' }">
  <header class="row align-items-center">
    <div class="col-md-10">
      <h3><a [routerLink]="mjsJob.url" [innerHtml]="mjsJob.title + ' - ' + (mjsJob.job_number || mjsJob.id)" [queryParams]="jobQueryParams(mjsJob)"></a></h3>
      <p [innerHtml]="mjsJob.address + ' ' + mjsJob.postcode"></p>
<!--      <div class="dropdown">-->
<!--        <p>-->
<!--          <span [innerHtml]="locationsName?.[0] || 'Location not available'"></span>-->
<!--          <button *ngIf="locationsName.length > 1"-->
<!--                  class="btn btn-link py-0 dropdown-toggle"-->
<!--                  [attr.aria-expanded]="locationsOpen ? 'true' : 'false'"-->
<!--                  (click)="locationsOpen = !locationsOpen">-->
<!--            + {{ locationsName.length - 1 }} more-->
<!--          </button>-->
<!--        </p>-->
<!--        <div *ngIf="locationsOpen" class="fixed-top w-100 h-100 top-0 left-0 right-0 bottom-0" (click)="locationsOpen = false"></div>-->
<!--        <div class="dropdown-menu shadow px-0 overflow-auto" [ngClass]="{ show: locationsOpen }">-->
<!--          <span *ngFor="let item of locationsName" class="dropdown-item border-bottom" [innerHtml]="item"></span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="col-md-2 organisation-link">
      <a [routerLink]="mjsJob.org_url">
        <img
          defaultImage="/assets/img/defaults/default-icon.svg"
          [src]="webp.defaultImage(mjsJob.org_logo) | mjsJobAssetUrl"
          [alt]="mjsJob.org_name"
          class="lazyload mr-3"
        />
      </a>
    </div>
  </header>
  <div class="row no-gutters">
    <dl class="col row no-gutters">
      <div class="col col-md-6 d-md-none d-lg-none" *ngIf="mjsJob.org_name">
        <dt>Organisation</dt>
        <dd><div [innerHtml]="ellipsis(mjsJob.org_name)"></div></dd>
      </div>
      <div class="col col-md-6" *ngIf="mjsJob.c_type_name">
        <dt>Contract Type</dt>
        <dd><div [innerHtml]="ellipsis(mjsJob.c_type_name)"></div></dd>
      </div>
      <div class="col col-md-6" *ngIf="mjsJob.p_type_name">
        <dt>Position Type</dt>
        <dd><div [innerHtml]="ellipsis(mjsJob.p_type_name)"></div></dd>
      </div>
      <div class="col col-md-6">
        <dt>Salary</dt>
        <dd><div>{{ mjsJob | mjsJobSalaryDisplay : { length: 40 } }}</div></dd>
      </div>
      <div class="col col-md-6" *ngIf="mjsJob.end_date">
        <dt>Closing Date</dt>
        <dd><div>{{ (mjsJob.end_date | mjsJobClosingDate) | date: 'dd/MM/yyyy' }}</div></dd>
      </div>
    </dl>
    <div class="col d-flex justify-content-center flex-column job-listing-actions">
      <button
        (click)="favourite()"
        class="btn btn-outline-primary btn-block d-flex justify-content-between align-items-center"
        [ngClass]="{'liked': mjsJob.user_like}">
        {{ mjsJob.user_like ? 'Unf' : 'F' }}avourite <span class="sr-only" [innerHtml]="mjsJob.title"></span>
        <svg aria-hidden="true" class="float-right" height="18" viewBox="0 0 24 24" width="18">
          <path fill="currentColor" *ngIf="!mjsJob.user_like" d="M12 15.422l3.75 2.25-0.984-4.266 3.328-2.906-4.406-0.375-1.688-4.031-1.688 4.031-4.406 0.375 3.328 2.906-0.984 4.266zM21.984 9.234l-5.438 4.734 1.641 7.031-6.188-3.75-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609z"></path>
          <path *ngIf="mjsJob.user_like" fill="currentColor" d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"></path>
        </svg>
      </button>
      <mjs-apply-button [mjsJob]="mjsJob" [style]="'minimal'"></mjs-apply-button>
    </div>
  </div>
</article>
